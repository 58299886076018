body {
  margin: 0;
  font-family: Trebuchet MS, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #222733 !important;
  background: url('./Assets/figure.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

a {
  color: #dbe8d4;
}

h3 {
  margin: 20px 0px;
  font-size: 22px;
}

h1 {
  margin: 20px 0px;
}

h1 {
  font-size: 40px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex-header {
  background-color: #2a3245;
  color: #dbe8d4;
  position: fixed;
  width: 100vw;
  left: 0;
  right: 0;
  margin: auto;
  border-top: 1px solid #dbe8d4;
  border-bottom: 1px solid #dbe8d4;
}

.text-header {
  max-width: 900px;
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.hero-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-padding {
  padding: 150px 0px;
}

.content-wrapper {
  max-width: 900px;
  margin: auto;
}

.portfolio-card {
  margin: 30px 0px !important;
  padding: 20px;
  /* border: 1px solid #dbe8d4; */
}

.headshot {
  width: 250px;
  height: auto;
  margin: 0px 40px;
  border: 1px solid;
}

.hr {
  height: 5px;
  border: 0px solid #dbe8d4;
  border-top-width: 1px;
}

.port-icon {
  height: 100px;
  width: 100px;
  margin: 10px 0px;
  border-radius: 64px;
}

@media screen and (max-width: 700px) {

  body {
    background: none;
  }

  .hero-container {
    flex-direction: column;
  }

  .section-padding {
    padding: 75px 0px;
  }

  h3 {
    font-size: 16px;
  }
}

